@import '~@taiga-ui/core/styles/taiga-ui-theme';
@import '~@taiga-ui/core/styles/taiga-ui-fonts';
@import '~@taiga-ui/styles/taiga-ui-global';

:root {

  // ------------------------------
  // GENERAL
  // ------------------------------
  --gap-s: 0.5rem;
  --gap-m: 1rem;
  --gap-l: 2rem;


  --cover-color: #fdf4e3;

  // ------------------------------
  // THEME SPECIFIC
  // ------------------------------
  // Черный в белой теме, слегка приглушенный в черной
  --text-01-night-02: var(--tui-text-01);

  //--app-background: rgba(248, 211, 214, .25);
  --app-background: #fff;
  --app-background--home: linear-gradient(78.87deg, #fff -3.32%, #f85e36 112.02%), linear-gradient(180deg, transparent, #fff);

  //--app-light-bg: #eaf1fb;
  --app-light-bg: rgba(0,0,0,.01);

  --app-logo: var(--tui-text-01);
  --app-logo-hover: -webkit-linear-gradient(#0074e8, #5e1ffe);

  // editor
  --editor-selected-line-bg: rgba(255, 248, 197, 1);
  --editor-cursor-color: #000;


  ///
  --bold-color: 255,255,255;
  --bold-revert-color: 0,0,0;

  --bold-bg: rgba(var(--bold-color));
  --bold-revert-bg: rgba(var(--bold-revert-color));
  --light-revert-bg: rgba(var(--bold-revert-color, .3));

  --shadow-box: 0 0 0 1px rgba(var(--bold-revert-color), 0.07), 0 2px 6px rgba(var(--bold-revert-color), 0.075), 0 1px 4px rgba(var(--bold-revert-color), 0.07), 0 8px 32px rgba(var(--bold-revert-color), 0.07);
  --shadow-box-light: 0 0 0 1px rgba(var(--bold-revert-color), 0.04), 0 2px 6px rgba(var(--bold-revert-color), 0.05), 0 1px 4px rgba(var(--bold-revert-color), 0.045), 0 8px 32px rgba(var(--bold-revert-color), 0.045);

  --background-ligh: rgb(245, 246, 249);


  --theme-toggle-color: #d77e24;

  --tui-tree-item-indent: 10px;

  --selected-file-bg: rgba(103, 136, 255, 0.3);

  --light-color: rgba(0,0,0,.2);
  --ultra-light-color: rgba(0,0,0,.05);

  --player-timeline-color: rgba(0, 0, 0, .1);


  //
  --landing-base-bg: rgba(255,255,255,.9);


  // PLAYER CONTROLS
  --icon_play: url(/assets/icons/replayer/track-play.svg);
  --icon_pause: url(/assets/icons/replayer/track-pause.svg);
  --icon_track-prev: url(/assets/icons/replayer/track-prev.svg);
  --icon_track-next: url(/assets/icons/replayer/track-next.svg);

  // comment
  --active-comment-bg: #ffefaa;

  --editor-right-sidebar-width: 450px;
  @media (max-width: 1200px) {
    --editor-right-sidebar-width: 350px;
  }
  @media (max-width: 800px) {
    --editor-right-sidebar-width: 1px;
  }

  --nav-color: #000;
  --record-btn-border: rgba(255,255,255,.7);

  --play-list-color: rgba(27, 31, 59, 1);

  --asi-assist-form-bg:  linear-gradient(90deg, #a19797 0%, rgba(131,77,240,1) 68%);
  --asi-assist-form-border-l:  #ccc;
}

html, body { height: 100%; }


body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: var(--app-background)!important;
  overflow: hidden;
}
html {
  background: #fdf4e3 !important;
}


@font-face {
  font-family: "JetBrains Mono";
  src: url(assets/font/jetbrains/JetBrainsMono-Medium.woff2) format("opentype");
}

.my-cursor {
  background: paleturquoise;
  width: 2px !important;
}

.monaco-editor .line-numbers {
  color: #cccccc;
}

//.monaco-editor .cursors-layer > .cursor {
//  display: none !important;
//}

.monaco-editor .line-numbers {
  font-size: 0.7rem;
  min-font-size: 10px;
}

.monaco-editor .view-overlays .current-line {
  //border: 1px solid rgba(255,255,255, .15);
  //border-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  border-right: 1px solid rgba(255,255,255, .15);
  border-top: 1px solid rgba(255,255,255, .15);
  border-bottom: 1px solid rgba(255,255,255, .15);
}
.editor textarea {
  outline: 0;
}

.monaco-editor .margin-view-overlays .current-line-margin  {
  border-radius: 0.3rem;
  border: 1px solid rgba(255,255,255, .15);
}

.monaco-editor .minimap-decorations-layer {
  background-color: rgba(0, 0, 0, 0) !important; /* Прозрачный фон */
}
.monaco-editor {
  outline: none !important;
}


tui-dialog[data-appearance="replay"] {
  & {
    border: none;
  }
  div.t-content {
    padding: 1rem 0.5rem;
    border-radius: 0.5rem;
  }
}


.monaco-editor .cursors-layer .cursor {
  color: orange!important;
}

